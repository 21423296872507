// @import "reset";

// @import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");

// body,
// html {
//   font-family: "Nunito", sans-serif;
//   scroll-behavior: smooth;
// }
// p {
//   font-size: 1.3rem;
//   letter-spacing: 0.1rem;
//   line-height: 1.4rem;
//   @media screen and (max-width: 768px) {
//     font-size: 1.1rem;
//     letter-spacing: 0.1rem;
//     line-height: 1.3rem;
//   }
// }
// h3 {
//   font-size: 1.4rem;
//   @media screen and (max-width: 768px) {
//     font-size: 1.3rem;
//   }
// }
body {
  overflow-y: scroll;
  scroll-behavior: smooth;
  white-space: pre-line;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f8fa;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f8fa;
}

body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #b1c8d8;
}
* {
  -webkit-overflow-scrolling: touch;
}
